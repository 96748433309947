<template>
    <div>
        <!-- <div class="Soon">Soon Here!<br />Made by Mike</div> -->
        <div class="container-desktop">
            <div class="meniudreapta">
                <router-link to="/">
                    <div class="logo-space">
                        <img src="../assets/logo.svg" class="img-fluid" alt="ArenaGaming" /></div
                ></router-link>
                <div class="menu">
                    <router-link
                        v-for="server in servers"
                        :key="server.address"
                        :id="server.address"
                        :to="server.address"
                    >
                        <p>
                            <img
                                :src="getImgUrl(server.img)"
                                :alt="server.address"
                                class="img-fluid"
                                width="60"
                                :class="currentRoute == '/games/' + server.address ? 'active' : ''"
                            />
                        </p>
                    </router-link>
                </div>
            </div>
            <div class="continut">
                <div class="content">
                    <transition name="fade" mode="out-in">
                        <CSGO v-if="currentRoute == '/games/csgo'" />
                        <Minecraft v-if="currentRoute == '/games/minecraft'" />
                        <Rust v-if="currentRoute == '/games/rust'" />
                    </transition>
                </div>
            </div>
        </div>

        <div class="container-mobile">
            <div class="central-container">
                <div class="menu">
                    <router-link to="/">
                        <img src="../assets/logo.svg" alt="Home" class="img-fluid" />
                    </router-link>
                    <router-link
                        v-for="server in servers"
                        :key="server.address"
                        :id="server.address"
                        :to="server.address"
                    >
                        <img
                            :src="getImgUrl(server.img)"
                            :alt="server.address"
                            class="img-fluid"
                            width="60"
                            :class="currentRoute == '/games/' + server.address ? 'active' : ''"
                        />
                    </router-link>
                </div>
                <div class="content">
                    <CSGO v-if="currentRoute == '/games/csgo'" />
                    <Minecraft v-if="currentRoute == '/games/minecraft'" />
                    <Rust v-if="currentRoute == '/games/rust'" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CSGO from '../components/CSGO.vue';
import Rust from '../components/Rust.vue';
import Minecraft from '../components/Minecraft.vue';
export default {
    props: ['servers'],
    name: 'Games',
    data: function () {
        return {
            currentRoute: null,
        };
    },
    methods: {
        getImgUrl(pic) {
            return require('../assets/' + pic);
        },
    },
    components: {
        CSGO,
        Minecraft,
        Rust,
    },
    mounted() {
        this.currentRoute = this.$router.history.current.path;
        this.routeWatcher = this.$watch(
            function () {
                return this.$route;
            },
            function () {
                this.currentRoute = this.$router.history.current.path;
            }
        );
    },
};
</script>
<style scoped lang="scss">
.container-desktop {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    flex-direction: row;
    gap: 15px;
    color: white;
    overflow-x: hidden;

    @media (max-width: 768px) {
        display: none;
    }

    .meniudreapta {
        width: 5vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .logo-space {
            background-color: #121212;
            border: 2px solid #121212;
            border-radius: 20px;
            width: 5vw;
            height: 10vh;
            margin-bottom: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                height: 5vh;
                width: auto;
                &:hover {
                    animation: pulse; /* referring directly to the animation's @keyframe declaration */
                    animation-duration: 0.8s; /* don't forget to set a duration! */
                }
            }
        }
        .menu {
            background-color: #121212;
            width: 5vw;
            height: auto;
            color: white;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            a {
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    height: 5vh;
                    width: auto;
                }
            }
            .active {
                background-color: darken(red, 10%);
                box-shadow: inset 0 0 5px black;
                padding: 5px;
                border-radius: 15%;
            }
        }
    }
    .continut {
        width: 93vw;
        .content {
            background-color: #121212;
            border: 2px solid #121212;
            box-shadow: 0px 0px 15px rgba(255, 0, 0, 0.5);
            border-radius: 20px;
            overflow-y: auto;
            overflow-x: hidden;
            height: 95vh;
            color: white;
            width: 100%;
        }
    }
}
.container-mobile {
    display: none;

    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    flex-direction: row;
    gap: 15px;
    color: rgb(75, 56, 56);
    overflow-x: hidden;
    @media (max-width: 768px) {
        display: flex;
    }

    .central-container {
        height: 100vh;
        width: 100vw;
        padding-left: 5px;
        padding-right: 5px;
        display: flex;
        justify-content: center;
        flex-direction: column;

        .menu {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            background-color: #121212;
            color: white;
            border: 2px solid #121212;
            border-radius: 25px;
            padding: 5px;
            margin-top: 5px;
            gap: 5px;
            height: 10vh;
            // width: 100%;

            /* height: 10vh; */
            a {
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .active {
                /* display: inherit; */
                background-color: rgba(255, 0, 0, 0.3);
                /* padding: 5px; */
                border-radius: 20%;
                // height: 50px;
            }
            img {
                // padding: 5px;
                height: 6vh;
                width: auto;
            }
        }

        .content {
            background-color: #121212;
            border: 2px solid #121212;
            border-radius: 25px;
            width: 100%;
            min-width: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            height: 100%;
            margin-top: 5px;
            margin-bottom: 5px;
            color: white;
        }
    }
}
</style>
