<template>
    <div>
        <div class="content-desktop">
            <div class="logo">
                <img src="../assets/Logo.png" alt="RichMan" />
            </div>
            <div class="games">
                <!-- <router-link
                    v-for="server in servers"
                    :key="server.address"
                    :id="server.address"
                    :to="'games/' + server.address"
                >
                    <div class="game">
                        <img :src="getImgUrl(server.img)" :alt="server.address" />
                    </div>
                </router-link> -->

                <a
                    v-for="server in servers"
                    :key="server.address"
                    :id="server.address"
                    :href="server.address"
                >
                    <div class="game">
                        <img :src="getImgUrl(server.img)" :alt="server.address" />
                    </div>
                </a>
            </div>
        </div>

        <div class="content-mobile">
            <div class="logo">
                <img src="../assets/Logo.png" alt="RichMan" width="250" />
            </div>
            <div class="games">
                <a
                    v-for="server in servers"
                    :key="server.address"
                    :id="server.address"
                    :href="server.address"
                >
                    <div class="element">
                        <img :src="getImgUrl(server.img)" :alt="server.address" width="60" />
                        {{ server.title }}
                    </div>
                </a>
                <!-- <router-link
                    v-for="server in servers"
                    :key="server.address"
                    :id="server.address"
                    :to="'games/' + server.address"
                >
                    <div class="element">
                        <img :src="getImgUrl(server.img)" :alt="server.address" width="60" />
                        {{ server.title }}
                    </div>
                </router-link> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['servers'],
    name: 'Index',
    data: function () {
        return {};
    },
    methods: {
        getImgUrl(pic) {
            return require('../assets/' + pic);
        },
    },
    mounted() {
        setTimeout(() => {
            var type = 1,
                radius = '30vh',
                start = -90,
                $elements = document.querySelectorAll('div.game'),
                numberOfElements = type === 1 ? $elements.length : $elements.length - 1,
                slice = (360 * type) / numberOfElements;
            var count = 0;
            $elements.forEach((element) => {
                var rotate = slice * count + start,
                    rotateReverse = rotate * -1;
                count++;
                element.style.transform =
                    'rotate(' +
                    rotate +
                    'deg) translate(' +
                    radius +
                    ') rotate(' +
                    rotateReverse +
                    'deg)';
            });
        }, 1);
    },
};
</script>
<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Mohave&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.content-desktop {
    // position: relative;
    color: white;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    @media (max-width: 768px) {
        display: none;
    }

    .logo {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 35vh;
        width: 35vh;
        background-color: #121212;
        // border: 2px solid #121212;
        border-radius: 50%;
        z-index: 2;
        // opacity: 0.5;
        img {
            &:hover {
                animation: pulse; /* referring directly to the animation's @keyframe declaration */
                animation-duration: 0.8s; /* don't forget to set a duration! */
            }
        }
    }
    .logo img {
        height: 25vh;
        width: 25vh;
    }
    .games {
        display: flex;
        justify-content: center;
        align-items: center;
        // height: 5vh;
        // width: 5vh;
        a {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .game {
            img {
                height: 10vh;
                width: 10vh;
            }
            position: absolute;
            background-color: #121212;
            border-radius: 50%;
            padding: 3vh;
            -webkit-transition: all 1s ease;
            -moz-transition: all 1s ease;
            transition: all 1s ease;
            z-index: 1;
            &:hover {
                background-color: darken(red, 10%);
                box-shadow: inset 0 0 25px #000000;
                cursor: pointer;
                // border: 3px solid white;
            }
        }
    }
}
.content-mobile {
    display: none;

    @media (max-width: 768px) {
        display: flex;
    }

    color: white;
    font-family: 'Montserrat', sans-serif;
    // display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        /* text-align: center; */
        height: 30vh;
    }

    .games {
        height: 70vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        width: 100%;
    }
    .games a {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 15px;
        /* padding: 10px; */
    }
    .games a .element {
        display: flex;
        /* justify-content: center; */
        align-items: center;
        gap: 10px;
        background-color: rgba(53, 53, 53, 0.75);
        border-radius: 15px;
        padding: 10px;
        font-size: 25px;
        width: 80%;
        font-family: 'Mohave', sans-serif;
        color: white;
        font-weight: 500;
    }
}
</style>
