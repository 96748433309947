import Vue from 'vue';
import VueRouter from 'vue-router';
import Index from '../views/Index.vue';
import Games from '../views/Games.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Index,
    },
    {
        path: '/games/:game',
        name: 'Games',
        component: Games,
    },
];

const router = new VueRouter({
    base: process.env.BASE_URL,
    routes,
});

export default router;
