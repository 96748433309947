<template>
    <div class="app">
        <transition name="fade" mode="out-in">
            <router-view v-bind:servers="servers" />
        </transition>
    </div>
</template>

<script>
export default {
    data: function () {
        return {
            servers: [
                // { address: 'csgo', img: 'csgo.png', title: 'CS:GO' },
                // { address: 'fivem', img: 'fivem.png', title: 'FiveM' },
                // { address: 'altv', img: 'altv.png', title: 'AltV' },
                { address: 'https://rust.richman.ro', img: 'rust.png', title: 'Rust' },
                {
                    address: 'https://discord.gg/gqMKmpbEd6',
                    img: 'discord.png',
                    title: 'Discord',
                },
            ],
            transitionName: 'x',
        };
    },
    watch: {
        $route(to, from) {
            const toDepth = to.path.split('/').length;
            const fromDepth = from.path.split('/').length;
            this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';
        },
    },
};
</script>
<style lang="scss">
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
a {
    text-decoration: none;
}
body {
    height: 100vh;
    widows: 100vw;
    margin: 0px;
    padding: 0px;
    background: #1a1a1a;
}
.app {
    height: 100vh;
    widows: 100vw;
}
/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #1a1a1a;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: red;
    transition: all 0.4s ease;
    &:hover {
        background: darken(red, 10%);
        transition: all 0.4s ease;
    }
}
</style>
